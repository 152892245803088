// import Parallax from "parallax-js"; // Using CDN since there was a problem with compiling. Turns out needed to downgrade 1 package in order for it to work
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { Draggable } from "gsap/Draggable.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";

gsap.registerPlugin(ScrollTrigger, Draggable, ScrollToPlugin);

export const showAccordion = function () {
    const groups = gsap.utils.toArray(".accordion-group");
    const menus = gsap.utils.toArray(".accordion-menu");
    const animations = [];

    groups.forEach((group) => createAnimation(group));

    menus.forEach((menu) => {
        menu.addEventListener("click", () => toggleAnimation(menu));
    });

    function toggleAnimation(menu) {
        // Save the current state of the clicked animation
        const selectedReversedState = menu.animation.reversed();

        // Reverse all animations
        // animations.forEach((animation) => animation.reverse());

        // Set the reversed state of the clicked accordion element to the opposite of what it was before
        menu.animation.reversed(!selectedReversedState);
    }

    function createAnimation(element) {
        const menu = element.querySelector(".accordion-menu");
        const box = element.querySelector(".accordion-content");

        gsap.set(box, { height: "auto" });

        // Keep a reference to the animation on the menu item itself
        const tween = gsap.from(box, {
            height: 0,
            duration: 0.5,
            ease: "power1.inOut",
            reversed: true,
        });

        menu.animation = tween;
        animations.push(tween);
    }
};

export const animateFaqs = function () {
    console.log("FAQ");
    const accordion_items = document.querySelectorAll(".faq");

    function handlerClick() {
        console.log("Clicked");
        this.classList.toggle("faq--active");
    }

    accordion_items.forEach((item) =>
        item.addEventListener("click", handlerClick)
    );
};
