// import Parallax from "parallax-js"; // Using CDN since there was a problem with compiling. Turns out needed to downgrade 1 package in order for it to work
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { Draggable } from "gsap/Draggable.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";

gsap.registerPlugin(ScrollTrigger, Draggable, ScrollToPlugin);

export const pinsceneAnimation = function () {
  // Scroll Scenes
  const scenes = gsap.utils.toArray(".pinscene");

  // maybe use dymanic height for pin/scroll ends?
  const height = (scenes.length - 1) * 200 + "%";

  // Scenes Timeline
  const pinTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".pinscenes__items",
      pin: ".pinscenes",
      start: "center center",
      end: `+=${height}`,
      // onEnterBack: () => startVideo(scenes[scenes.length - 1]),
      scrub: true,
    },
  });

  // Set scenes wrapper to absolute
  gsap.set(scenes, { position: "absolute", top: 0 });

  // Loop over scenes
  scenes.forEach(function (elem, i) {
    // Vid start / pause logic
    pinTl.add(
      () =>
        pinTl.scrollTrigger.direction > 0
          ? startVideo(elem, i)
          : stopVideo(elem, i),
      i + 0.001
    );

    if (i != 0) {
      // Scene Enter animations
      pinTl.from(
        elem.querySelector(".pinscene__figure"),
        {
          autoAlpha: 0,
        },
        i
      );

      pinTl.from(
        elem.querySelector(".pinscene__header"),
        {
          autoAlpha: 0,
          translateY: 100,
        },
        i
      );
    }

    // Scene Exit animations
    if (i != scenes.length - 1) {
      pinTl.to(
        elem.querySelector(".pinscene__header"),
        {
          autoAlpha: 0,
          translateY: -100,
        },
        i + 0.75
      );
      pinTl.to(
        elem.querySelector(".pinscene__figure"),
        {
          autoAlpha: 0,
        },
        i + 0.75
      );
    }

    // Vid start / pause logic
    pinTl.add(
      () =>
        pinTl.scrollTrigger.direction > 0
          ? stopVideo(elem, i)
          : startVideo(elem, i),
      i + 1.25
    );
  });

  // add a tiny amount of empty space at the end of the timeline so that the playhead trips the final callback in both directions
  pinTl.to({}, { duration: 0.001 });

  /**
   * Start Video
   * @param {HTML ELement} - element containing video
   */
  function startVideo(vidScene, i) {
    const vid = vidScene.querySelector("video");
    console.log("start", i);
    if (vid) {
      // console.log("Start Vid", vid)
      vid.play();
    }
  }

  /**
   * Stop Video
   * @param {HTML ELement} - element containing video
   */
  function stopVideo(vidScene, i) {
    const vid = vidScene.querySelector("video");
    console.log("stop", i);
    if (vid) {
      // console.log("end vid", vid)
      vid.pause();
    }
  }

  console.log("Pinscene");
};
