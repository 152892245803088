import * as model from "./model.js";
import navbarView from "./views/navbarView.js";
// import modalView from "./views/modalView.js";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock";
// import Parallax from "parallax-js";


const navbarController = function () {
    model.controlNavbarVisibility();
};

const formController = function () {
    // console.log("START"); // event.preventDefault();
    document.addEventListener("submit", function (event) {
        if (event.target.matches("#formNewProjectRequest")) {
            event.preventDefault();
            model.globalContactRequest();
            // handler(event);
        }
    });
    document.addEventListener("submit", function (event) {
        // event.preventDefault();
        if (event.target.matches("#formContact")) {
            event.preventDefault();
            model.globalContactRequest("contact");
            // handler(event);
        }
    });
};

// gsap.from("#block1title", {
//   duration: 0.5,
//   y: 100,
//   opacity: 0,
//   // rotation: -360,
//   ease: "linear",
//   // scrollTrigger: "#block1title",
//   scrollTrigger: {
//     trigger: "#block1title",
//     markers: true,
//     // start: "top 75%",
//     // end: "bottom 25%",
//     // events for toggleActions: onEnter onLeave onenterBack onLeaveBack
//     // all options: play, pause, resume, reset, restart, complete, reverse, none
//     toggleActions: "restart none restart reset",
//   },
// });

// const testBtn = document.getElementById("heroSubtitle");
// testBtn.addEventListener("click", (e) => {
//   console.log("TEST");
//   disableBodyScroll(testBtn);
// });

const controlHamburger = function () {
    // model.getIndicator(indicatorId);
    // carouselView.render(model.state.carouselData);
    model.toggleHamburgerMenu();
    // console.log("Clicked hamburger");
};

const controlNavbarDropdown = function () {
    // console.log("Clicked dropdown");
    model.toggleNavbarDropdown();
};

// Global scripts like navbar, etc
const init = function () {
    //   language.loadLanguage();
    
    model.preLoader();
    navbarController(); // Controls general visibility of the navbar
    navbarView.addHandlerNavbarHamburger(controlHamburger);
    navbarView.addHandlerNavbarDropdown(controlNavbarDropdown); // unfinished

    model.globalModalRequest(); // Open/close modal
    // modalView.addHandlerFormSubmit(controlOrderForm); // Submit AJAX Order form
    model.textareaAutoHeight("modalMessage");
    formController();

    model.globalScrollToButtons();
    model.globalBackgroundChange();
    model.globalAnimationStyles(); // Currently used for reveal effect
    // console.log("Hello and welcome to my website!");

    //Page specific scritps
    model.animateHome();
    model.animateWebdev();
    model.animateSuperheroClouds();
    model.animateBrands();
};

window.onload = function () {
    init();
};
