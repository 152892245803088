import View from "./View";

class NavbarView extends View {
  _parentElement = document.querySelector(".configurator");
  _errorMessage = "Error message";

  //   const menuBtn = document.querySelector(".menu-btn");

  addHandlerNavbarHamburger(handler) {
    document.addEventListener("click", function (event) {
      if (event.target.matches(".menu-btn")) {
        handler(event.target.value);
      }
    });
  }

  addHandlerNavbarDropdown(handler) {
    document.addEventListener("click", function (event) {
      if (event.target.matches(".expand-btn")) {
        handler(event.target.value);
      }
    });
  }

  _generateMarkup() {
    // console.log(language.str);
    return `
    <div class="container">
    <h1>${language.str.txtConfModelViewTitle}</h1>
    ${this._generateModelMarkupNEW()}
  </div>`;
  }
}

export default new NavbarView();
