// import Parallax from "parallax-js"; // Using CDN since there was a problem with compiling. Turns out needed to downgrade 1 package in order for it to work
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { Draggable } from "gsap/Draggable.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";

gsap.registerPlugin(ScrollTrigger, Draggable, ScrollToPlugin);

export const homeAnimateClientLogos = function () {
    // console.log("Home module");
};

export const homePuzzle = function () {
    // console.log("Puzzle ready");
    gsap.from("#svg-puzzle2", {
        x: 400,
        // opacity: 0,
        rotation: 180,
        transformOrigin: "50% 50%",
        scrollTrigger: {
            trigger: "#svg-puzzle",
            start: "top bottom",
            end: "top 25%",
            scrub: true,
            // markers: true,
        },
    });
};

export const heroCharts = function () {
    const homeChartExists = document.getElementById("charts-path1");
    if (!homeChartExists) return;

    const paths = document.querySelectorAll(
        "#charts-path1, #charts-path2, #charts-path3, #charts-path4"
    );
    const circles = document.querySelectorAll(
        "#charts-circle1, #charts-circle2, #charts-circle3"
    );

    // for (let i = 0; i < paths.length; i++) {
    //   console.log(paths[i]);
    // }

    const p1attr = getLineAttributes(paths[0]);
    const p2attr = getLineAttributes(paths[1]);
    const p3attr = getLineAttributes(paths[2]);
    const p4attr = getLineAttributes(paths[3]);

    const tl = gsap.timeline();

    const circleFrom = { autoAlpha: 0, opacity: 0, scale: 0.1 };
    const circleTo = {
        autoAlpha: 1,
        opacity: 1,
        scale: 1,
        duration: 0.5,
        ease: "Elastic.easeOut",
    };

    // prettier-ignore
    {
    tl.set(paths[0],{autoAlpha: 1});
    tl.set(paths[1],{autoAlpha: 1});
    tl.set(paths[2],{autoAlpha: 1});
    tl.set(paths[3],{autoAlpha: 1});
    tl.fromTo(paths[0], p1attr[0], p1attr[1], "-=0");
    tl.fromTo(circles[0],circleFrom, circleTo);
    tl.fromTo(paths[1], p2attr[0], p2attr[1], "-=0.5");
    tl.fromTo(circles[1],circleFrom, circleTo);
    tl.fromTo(paths[2], p3attr[0], p3attr[1], "-=0.5");
    tl.fromTo(circles[2],circleFrom, circleTo);
    tl.fromTo(paths[3], p4attr[0], p4attr[1], "-=0.5");
  }
    function getLineAttributes(el) {
        const x1 = el.getAttribute("x1");
        const y1 = el.getAttribute("y1");
        const x2 = el.getAttribute("x2");
        const y2 = el.getAttribute("y2");

        const attr1 = { x2: x1, y2: y1 };
        const attr2 = { x2: x2, y2: y2 };

        const pathFrom = { attr: attr1 };
        const pathTo = { duration: 0.5, attr: attr2 };

        return [pathFrom, pathTo];
    }
    return tl;
};

export const heroTitleSection = function () {
    const tl = gsap.timeline();
    // prettier-ignore
    {
    tl.from("#heroTitle", { duration: 0.5, x: "-100%", autoAlpha: 0 },"+=1");
    tl.from("#heroSubtitle", { duration: 0.5, x: "-100%", autoAlpha: 0 },"-=0.3");
    tl.from(".btn--hero", {autoAlpha: 0, opacity: 0, duration: 2, scale: 0.1, ease: "Elastic.easeOut"},"-=0");
  }
    return tl;
};

export const heroWebdevLoad = function () {
    // This method sets up hero for initial load to later use for ScrollTrigger animations
    let tl = gsap.timeline();
    // prettier-ignore
    {
      tl.set("#hero-webdev--back", { opacity: 0 });
      tl.set("#hero-webdev--icon1, #hero-webdev--icon2, #hero-webdev--icon3, #hero-webdev--person1, #hero-webdev--person2", {opacity: 0});


      tl.from("#hero-webdev",{duration: 1, y: 600, opacity: 0,autoAlpha: 0, stagger: 0.3, ease: "Elastic.easeOut",},"-=0.5");
      tl.from("#hero-webdev--person1, #hero-webdev--person2", {duration: 1, scale:0.1, autoAlpha: 0, stagger: 0.3, ease: "Elastic.easeOut",},"-=1");
      tl.from("#hero-webdev--icon1, #hero-webdev--icon2, #hero-webdev--icon3", {duration: 1, scale:0.1, opacity: 0,autoAlpha: 0, stagger: 0.1, ease: "Elastic.easeOut",},"-=1");
      tl.from("#hero-webdev--external-cog1",{duration: 0.5,scale: 0.1,opacity: 0,autoAlpha: 0,},"-=1.5");
      tl.from( "#hero-webdev--external-cog2", {duration: 0.5, scale: 0.1, opacity: 0, autoAlpha: 0, }, "-=1" );

    }
    return tl;
};

export const heroWebdevAnimate = function () {
    // console.log("Home hero");
    let tl = gsap.timeline();
    const myScrollTrigger = {
        trigger: ".hero",
        start: "top",
        endTrigger: ".hero",
        end: "bottom",
        scrub: 0,
        // markers: true,
    };

    //prettier-ignore
    {
      // THESE IS NOT PART OF MAIN SVG ANIMATION
      tl.to("#hero-webdev--external-cog1", {rotate: 90, y: 240, scrollTrigger: myScrollTrigger});
      tl.to("#hero-webdev--external-cog2", {rotate: -90,y: 240,scrollTrigger: myScrollTrigger,});
      // tl.to(".hero-text-box", {y: 80,scrollTrigger: myScrollTrigger,});
      //End -  THIS IS NOT PART OF MAIN SVG
      // tl.set("#hero-webdev--person1", { x: -600 });
      tl.to("#hero-webdev--person1", {y: -100, x: -200, scrollTrigger: myScrollTrigger});
      tl.to("#hero-webdev--person2", {y: -100, x: 200, scrollTrigger: myScrollTrigger});
      tl.to("#hero-webdev--device", {y: -300,scale: 1.15,scrollTrigger: myScrollTrigger,});
      tl.to("#hero-webdev--icon1", {y: -4200,scrollTrigger: myScrollTrigger,});
      tl.to("#hero-webdev--icon2", {y: -2600,scrollTrigger: myScrollTrigger,});
      tl.to("#hero-webdev--icon3", {y: -1000,scrollTrigger: myScrollTrigger});
      tl.to("#hero-webdev--cogset1", {y: 1000,scrollTrigger: myScrollTrigger,});
      tl.to("#hero-webdev--cogset2", {y: 800,scrollTrigger: myScrollTrigger,});
    }
    return tl;
};

export const homeTabs = function () {
    // console.log("Home tabs");
    let targets = document.querySelectorAll(".tabs-pill");
    let articles = document.querySelectorAll(".tabs-info");
    let activeTab = 0;
    let old = 0;
    let heights = [];
    let dur = 0.4;
    let animation;
    for (let i = 0; i < targets.length; i++) {
        targets[i].index = i;
        heights.push(articles[i].offsetHeight); // get height of each article
        gsap.set(articles[i], { top: 0, y: -heights[i] }); // push all articles up out of view
        targets[i].addEventListener("click", doCoolStuff);
    }
    // set initial article and position bubble slider on first tab
    gsap.set(articles[0], { y: 0 });
    gsap.set(".tabs-slider", {
        x: targets[0].offsetLeft,
        width: targets[0].offsetWidth,
    });
    gsap.set(targets[0], { color: "#fff" });
    gsap.set(".tabs-info-block", { height: heights[0] });

    function doCoolStuff() {
        // check if clicked target is new and if the timeline is currently active
        if (this.index != activeTab) {
            //if there's an animation in-progress, jump to the end immediately so there aren't weird overlaps.
            if (animation && animation.isActive()) {
                animation.progress(1);
            }
            animation = gsap.timeline({ defaults: { duration: 0.4 } });
            old = activeTab;
            activeTab = this.index;
            // animate bubble slider to clicked target
            animation.to(".tabs-slider", {
                x: targets[activeTab].offsetLeft,
                width: targets[activeTab].offsetWidth,
            });
            // change text color on old and new tab targets
            targets[old].classList.remove("tabs-pill--active");
            targets[old].classList.add("tabs-pill--inactive");
            targets[activeTab].classList.remove("tabs-pill--inactive");
            targets[activeTab].classList.add("tabs-pill--active");

            animation.to(targets[old], { color: "grey", ease: "none" }, 0);
            animation.to(
                targets[activeTab],
                { color: "#fff", ease: "none" },
                0
            );
            // slide current article down out of view and then set it to starting position at top
            animation.to(
                articles[old],
                { y: heights[old], ease: "back.in" },
                0
            );
            animation.set(articles[old], { y: -heights[old] });
            // resize article block to accommodate new content
            animation.to(".tabs-info-block", { height: heights[activeTab] });
            // slide in new article
            animation.to(
                articles[activeTab],
                { duration: 1, y: 0, ease: "elastic" },
                "-=0.25"
            );
        }
    }
};
