// import Parallax from "parallax-js"; // Using CDN since there was a problem with compiling. Turns out needed to downgrade 1 package in order for it to work
import { gsap } from "gsap";
import * as home from "./models/home.js";
import * as pinscene from "./blocks/pinscene.js";
import * as global from "./models/global.js";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { Draggable } from "gsap/Draggable.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
    BodyScrollOptions,
} from "body-scroll-lock";

import { DEFAULT_VALUE } from "./config.js";

gsap.registerPlugin(ScrollTrigger, Draggable, ScrollToPlugin);

export const state = {
    testData: {
        testPage: 1,
        testArray: [],
        testObj: {},
    },
    configuratorData: {
        defaultValue: DEFAULT_VALUE,
    },
    userData: {},
};

export const preLoader = function () {
    // console.log("Works");
    const loadingScene = document.querySelector("#loadingScene");
    const tl = gsap.timeline();
    tl.to("#loadingLogo", { duration: 0.2, scale: 2 });
    tl.to("#loadingLogo", { duration: 0.4, scale: 0.1, opacity: 0 });
    tl.to(
        "#loaderCircle",
        { duration: 0.5, scale: 300, autoAlpha: 1 },
        "-=0.3"
    );
    tl.to(
        "#loadingScene",
        {
            duration: 0.5,
            autoAlpha: 0,
            onComplete: () => removeLoader(),
        },
        "+=0"
    );
    function removeLoader() {
        loadingScene.parentNode.removeChild(loadingScene);
        document.body.classList.remove("loading");
    }
    return;
};

export const textareaAutoHeight = function (elId) {
    // console.log(elId);
    const elem = document.getElementById(elId);
    // console.log(elem);
    elem.addEventListener("keyup", (e) => {
        e.target.style.height = 0; // Don't take the max() of our current height and the content height.
        e.target.style.height = elem.scrollHeight + "px";
    });
};

export const globalContactRequest = async function (formID) {
    // console.log("WORKS");
    // function logSubmit(event) {
    //   log.textContent = `Form Submitted! Time stamp: ${event.timeStamp}`;
    //   event.preventDefault();
    // }
    // const form = document.getElementById("formNewProjectRequest");
    // const submitBtn = document.getElementById("formSubmitNewProject");
    // const log = document.getElementById("log");
    // // submitBtn.addEventListener("click", logSubmit);
    // form.addEventListener("submit", logSubmit);

    let formWrapper = document.getElementById("modalFormWrapper");
    let formSuccess = document.getElementById("modalFormSuccess");
    let errContainer = document.getElementById("responseContainer");
    let form = document.getElementById("formNewProjectRequest");
    let btn = document.getElementById("formSubmitNewProject");
    let spinner = document.getElementById("modalSpinner");

    // if contact form is sent from contact page
    if (formID === "contact") {
        formWrapper = document.getElementById("contactFormWrapper");
        formSuccess = document.getElementById("contactFormSuccess");
        errContainer = document.getElementById("responseContainerContacts");
        form = document.getElementById("formContact");
        btn = document.getElementById("formSubmitContact");
        spinner = document.getElementById("contactSpinner");
    }

    // console.log(form.action);
    // btn.disabled = true;
    btn.classList.add("display-none");
    spinner.classList.add("is-visible");
    errContainer.classList.add("is-hidden");
    formSuccess.classList.add("display-none");

    // return true;

    const rawResponse = await fetch(form.action, {
        method: form.method,
        body: new FormData(form),
    });

    const response = await rawResponse.json();
    // console.log(response);
    if (!response.success) {
        errContainer.classList.remove("is-hidden");
        btn.classList.remove("display-none");
        spinner.classList.remove("is-visible");
        // btn.disabled = false;
        let markup = '<div class="pb-2">';
        for (let [index, errorMsg] of Object.entries(response.errors)) {
            markup += formErrorMessage(index, errorMsg);
        }
        markup += "</div>";
        errContainer.innerHTML = markup;
    } else {
        // console.log("SUCCESS");

        formWrapper.classList.remove("display-block");
        formWrapper.classList.add("display-none");
        formSuccess.classList.remove("display-none");
        formSuccess.classList.add("display-block");
        // document.getElementById("modalSignup").checked = false;
        spinner.classList.remove("is-visible");
        btn.classList.remove("display-none");
        // console.log(response.data, typeof response.data);
        // setUser(response.data);
    }
};

// export const globalNewProjectRequest = async function () {
//     // console.log("WORKS");
//     // function logSubmit(event) {
//     //   log.textContent = `Form Submitted! Time stamp: ${event.timeStamp}`;
//     //   event.preventDefault();
//     // }
//     // const form = document.getElementById("formNewProjectRequest");
//     // const submitBtn = document.getElementById("formSubmitNewProject");
//     // const log = document.getElementById("log");
//     // // submitBtn.addEventListener("click", logSubmit);
//     // form.addEventListener("submit", logSubmit);

//     // console.log(form.action);

//     btn.disabled = true;
//     spinner.classList.add("is-visible");
//     errContainer.classList.add("is-hidden");
//     formSuccess.classList.add("display-none");

//     const rawResponse = await fetch(form.action, {
//         method: form.method,
//         body: new FormData(form),
//     });
//     const response = await rawResponse.json();
//     // console.log(response);
//     if (!response.success) {
//         errContainer.classList.remove("is-hidden");
//         let markup = '<div class="pb-2">';
//         for (let [index, errorMsg] of Object.entries(response.errors)) {
//             markup += formErrorMessage(index, errorMsg);
//         }
//         markup += "</div>";
//         errContainer.innerHTML = markup;
//         spinner.classList.remove("is-visible");
//         btn.disabled = false;
//     } else {
//         // console.log("SUCCESS");
//         formWrapper.classList.remove("display-block");
//         formWrapper.classList.add("display-none");
//         formSuccess.classList.remove("display-none");
//         formSuccess.classList.add("display-block");
//         // document.getElementById("modalSignup").checked = false;
//         spinner.classList.remove("is-visible");
//         btn.disabled = false;
//         // console.log(response.data, typeof response.data);
//         // setUser(response.data);
//     }
// };

const formErrorMessage = function (index, message) {
    //const el = document.getElementById("modal" + ucFirst(index));
    //el.classList.add("is-invalid");
    return `<p class="is-invalid">${message}</p>`;
};

export const globalModalRequest = function () {
    // console.log("MODAL");
    // const open = document.getElementById("open-modal");
    const close = document.getElementById("close-modal");
    const modalWrapper = document.getElementById("modal-wrapper");
    const modal = document.getElementById("modal");
    const timeline = gsap.timeline({ ease: "easeInOut", duration: 0.2 });
    // To check if hamburger open so we can disable body scroll if necessary
    const menuBtn = document.querySelector(".menu-btn");

    const openModalBtns = document.querySelectorAll(".open-modal");
    const closeModalBtns = document.querySelectorAll(".close-modal");

    // const animateOpenModal = () => {
    //   // console.log(el);
    //   // if (!menuBtn.classList.contains("open")) {
    //   //   clearAllBodyScrollLocks();
    //   // }
    //   disableBodyScroll(modalWrapper);
    //   // disableBodyScroll(modalWrapper, { reserveScrollBarGap: true });
    //   timeline
    //     .set(modalWrapper, { y: 0 })
    //     .to(modalWrapper, { clipPath: "circle(150% at 0 0)" })
    //     .to(modal, { y: 0, opacity: 1 });
    // };
    const animateCloseModal = () => {
        timeline
            .to(modal, { y: "20%", opacity: 0 })
            .to(modalWrapper, { clipPath: "circle(0% at 0 0)" })
            .set(modalWrapper, { y: "100%", onComplete: clearScrollForm });
    };

    const clearScrollForm = () => {
        // Checks if hamburger navigation is open
        if (!menuBtn.classList.contains("open")) {
            clearAllBodyScrollLocks();
        }
    };

    // Opens modal on click (checks all buttons with class .open-modal)
    openModalBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
            // Pre-select service buttons if data attribute is set (otherwise use defaults. see below)
            const preSelected = btn.dataset.preselected;
            const preSelectedArr = preSelected
                ? preSelected.split(",")
                : ["majas-lapas-izstrade"]; // default preselected if "data-preselected" is not specified

            if (preSelectedArr && preSelectedArr.length) {
                preSelectedArr.forEach((preSel) => {
                    // console.log(preSel);
                    // console.log(document.getElementById("modal-" + preSel));
                    document.getElementById("modal-" + preSel).checked = true;
                });
            }
            disableBodyScroll(modalWrapper);
            // disableBodyScroll(modalWrapper, { reserveScrollBarGap: true });
            timeline
                .set(modalWrapper, { y: 0 })
                .to(modalWrapper, { clipPath: "circle(150% at 0 0)" })
                .to(modal, { y: 0, opacity: 1 });

            // console.log("Open modal works", btn);
            // console.log(btn.dataset.preselected);
        });
    });

    closeModalBtns.forEach((btn) => {
        btn.addEventListener("click", (e) => {
            console.log("Close");
            animateCloseModal();
        });
    });

    // OLD modal open version
    // open.addEventListener("click", animateOpenModal);
    // close.addEventListener("click", animateCloseModal);
    modalWrapper.addEventListener("click", (e) => {
        if (e.target.className !== "modal-wrapper") return;
        animateCloseModal();
    });
};

export const animateHome = function () {
    const homeTitleExists = document.getElementById("heroTitle");
    if (!homeTitleExists) {
        return;
    }

    const master = gsap.timeline({
        onComplete: () => {
            mapAnimation();
        },
    });
    const homeTitle = home.heroTitleSection();
    const homeCharts = home.heroCharts();
    // const homeMap = mapAnimation();

    master.add(homeTitle.play());
    // .add(homeCharts.play(), ">-1.5");
    // .add(console.log("Finished"), ">");
    // home.heroCharts();
    // mapAnimation();
    // myAnimation.eventCallback("onComplete", myFunction, ["param1","param2"]);
    // home.heroWebdevLoad(); // Loads SVG and some hero elements
    // home.heroWebdevAnimate(); // Animates homepage hero
    home.homePuzzle();
    // home.homeTabs(); // Tabs with slider
    // home.homeAnimateClientLogos();
};
export const animateWebdev = function () {
    const heroTitleExists = document.getElementById("heroTitleWebdev");
    if (!heroTitleExists) {
        return;
    }
    // for FAQs
    global.showAccordion();
    // Master animation
    const master = gsap.timeline();

    const heroWebdevLoad = home.heroWebdevLoad(); // Loads SVG and some hero elements
    const heroWebdevAnimate = home.heroWebdevAnimate(); // Animates homepage hero

    master.add(heroWebdevLoad.play(), "+=1").add(heroWebdevAnimate.play());

    // home.heroWebdevLoad(); // Loads SVG and some hero elements
    // home.heroWebdevAnimate(); // Animates homepage hero
    // pinscene.pinsceneAnimation();
    // global.animateFaqs();
};

export const globalBackgroundChange = function () {
    // console.log("Global");
    gsap.utils.toArray(".color").forEach(function (elem) {
        const color = elem.getAttribute("data-color");
        ScrollTrigger.create({
            trigger: elem,
            start: "top 50%",
            end: "bottom 50%",
            onEnter: () => gsap.to("body", { backgroundColor: color }),
            onLeave: () => gsap.to("body", { backgroundColor: "#e1eff6" }),
            onLeaveBack: () => gsap.to("body", { backgroundColor: "#e1eff6" }),
            onEnterBack: () => gsap.to("body", { backgroundColor: color }),
            // markers: true,
        });
    });
};

export const animateBrands = function () {
    let container = document.querySelector(".brands");
    let tl = gsap.timeline({
    scrollTrigger: {
        pin: true,
        scrub: 1,
        trigger: container,
        end: () => `+=${container.scrollWidth - document.documentElement.clientWidth + container.offsetWidth}`
    },
    defaults: { ease: "none", duration: 1 }
    });

    tl.to(".parallax", { x: 300 })
    .to(".brand-panel", {
        x: () => `+=${-(container.scrollWidth - document.documentElement.clientWidth)}`
        },
        0
    )
    .from(".secondAn", {
        opacity: 0,
        scale: 0.25,
        duration: 0.2,
        stagger: {
            amount: 0.8
        }
        },
        0
    );

    gsap.from(".firstAn", {
    duration: 1,
    opacity: 0,
    scale: 0.25,
    scrollTrigger: {
        trigger: container,
        start: "top 90%",
        end: "bottom 10%",
        toggleActions: "play none none reverse"
    }
    });
}

export const animateSuperheroClouds = function () {
    const superheroMan = document.getElementById("superheroMan");
    if (!superheroMan) {
        return;
    }

    // console.log("Animate hero");

    // console.log("Home hero");
    let tl = gsap.timeline();
    const myScrollTrigger = {
        trigger: ".superhero-clouds",
        start: "top bottom",
        endTrigger: ".superhero-clouds",
        end: "center center 200",
        scrub: 0,
        // markers: true,
    };

    //prettier-ignore
    {
        // THESE IS NOT PART OF MAIN SVG ANIMATION
        // tl.to("#hero-webdev--external-cog1", {rotate: 90, y: 240, scrollTrigger: myScrollTrigger});
        // tl.to("#hero-webdev--external-cog2", {rotate: -90,y: 240,scrollTrigger: myScrollTrigger,});
        // // tl.to(".hero-text-box", {y: 80,scrollTrigger: myScrollTrigger,});
        // //End -  THIS IS NOT PART OF MAIN SVG
        // // tl.set("#hero-webdev--person1", { x: -600 });
        tl.from("#svg-cloud-1", {y: -50, x: -100, scrollTrigger: myScrollTrigger});
        tl.from("#svg-cloud-2", {y: -50, x: 200, scrollTrigger: myScrollTrigger});
        tl.from("#svg-cloud-3", {y: -50, x: -200, scrollTrigger: myScrollTrigger});
        tl.from("#svg-cloud-4", {y: 100, x: 0, scrollTrigger: myScrollTrigger});
        tl.from("#superheroMan", {y: 200,scale: 0.8,scrollTrigger: myScrollTrigger,});
        // tl.to("#hero-webdev--icon1", {y: -4200,scrollTrigger: myScrollTrigger,});
        // tl.to("#hero-webdev--icon2", {y: -2600,scrollTrigger: myScrollTrigger,});
        // tl.to("#hero-webdev--icon3", {y: -1000,scrollTrigger: myScrollTrigger});
        // tl.to("#hero-webdev--cogset1", {y: 1000,scrollTrigger: myScrollTrigger,});
        // tl.to("#hero-webdev--cogset2", {y: 800,scrollTrigger: myScrollTrigger,});
      }
    return tl;
};

export const globalScrollToButtons = function () {
    // Detect if a link's href goes to the current page
    function getSamePageAnchor(link) {
        if (
            link.protocol !== window.location.protocol ||
            link.host !== window.location.host ||
            link.pathname !== window.location.pathname ||
            link.search !== window.location.search
        ) {
            return false;
        }

        return link.hash;
    }

    // Scroll to a given hash, preventing the event given if there is one
    function scrollToHash(hash, e) {
        const elem = hash ? document.querySelector(hash) : false;
        if (elem) {
            if (e) e.preventDefault();
            gsap.to(window, { duration: 1, scrollTo: elem });
        }
    }

    // If a link's href is within the current page, scroll to it instead
    document.querySelectorAll("a[href]").forEach((a) => {
        a.addEventListener("click", (e) => {
            scrollToHash(getSamePageAnchor(a), e);
        });
    });

    // Scroll to the element in the URL's hash on load
    scrollToHash(window.location.hash);
};

export const controlNavbarVisibility = function () {
    // This hides navbar when scrolling down
    // and shows when scrolling up
    const navbar = document.querySelector(".navbar");
    gsap.set(".navbar__logo", {opacity: 0});

    const showNavBar = gsap.timeline();
    gsap.set(".navbar__logo", {y: 0, opacity: 0});
    // showNavBar.to(".navbar__logo", {duration: 0.5, y: 0, opacity: 1, ease: "linear",}).progress(1);
    showNavBar.from(".navbar", {duration: 0.5, y: -100, opacity: 1, ease: "linear",}).progress(1);
    





    // const showNavBar = gsap
    //     .from(".navbar", {
    //         duration: 0.5,
    //         y: -100,
    //         opacity: 0,
    //         ease: "linear",
    //     })
    //     .progress(1);

    ScrollTrigger.create({
        start: "top top",
        end: 99999,
        onUpdate: (self) => {
            //   console.log(self.progress, self.direction);
            //   self.direction === -1 ? showNavBar.play() : showNavBar.reverse();
            if (self.direction === -1) {
                navbar.classList.add("navbar--sticky");
                showNavBar.play();
                self.progress === 0
                    ? navbar.classList.remove("navbar--sticky")
                    : "";
            } else {
                showNavBar.reverse();
                navbar.classList.remove("navbar--sticky");
            }
        },
    });
    //   console.log("Controlling visibility");
};

export const toggleHamburgerMenu = function () {
    // localStorage.setItem("user", JSON.stringify(data));
    //   document.getElementById("orderClientId").value = data.client_id;
    //   document.getElementById("orderClientEmail").value = data.email;

    // const body = document.body;
    const navWrapper = document.getElementById("mainNav");
    const menuBtn = document.querySelector(".menu-btn");
    const menuItems = document.querySelector(".menu-items");

    menuBtn.classList.toggle("open");
    menuItems.classList.toggle("open");
    // console.log("Toggle now");

    if (menuBtn.classList.contains("open")) {
        // body.style.overflow = "auto";
        // document.getElementById("id_price").setAttribute("step","any");
        // console.log("HIDE BODY SCROLL");
        disableBodyScroll(navWrapper);
    } else {
        // console.log("SHOW BODY SCROLL");
        enableBodyScroll(navWrapper);
        // body.style.overflow = "hidden";
        // body.classList.remove("MyClass");
    }
};

export const toggleNavbarDropdown = function () {
    const expandBtn = document.querySelectorAll(".expand-btn");
    // drop down menu expand (ORIGINAL)
    // expandBtn.forEach((btn) => {
    //     btn.addEventListener("click", () => {
    //       btn.classList.toggle("open");
    //     });
    //   });
};

export const mapAnimation = function () {
    gsap.set(myFunc, {
        delay: 1,
        onRepeat: myFunc,
        repeat: -1,
        repeatDelay: 0.2,
        // repeatDelay: genRand(0.01, 0.5, 2),
    });

    function myFunc() {
        let squareEl = "#mapCircle" + genRand(0, 33, 0);
        const tl1 = gsap.timeline({ repeat: 0, yoyo: true });
        tl1.to(squareEl, {
            y: -20,
            x: -2,
            scale: 1.1,
            duration: 0.5,
            fill: "#ffffff",
            stroke: "#40508e",
            // stroke-width: "2",
            strokeWidth: 3,
            ease: "power3",
            // attr: { r: 50 },
        });
        tl1.to(squareEl, {
            y: 0,
            x: 0,
            scale: 1,
            duration: 1,
            strokeWidth: 0,
            stroke: "#ffffff",
            fill: "#ffffff",
            ease: "elastic",
            // attr: { r: 1 },
        });
        // tl1.reverse();
        // gsap.to(squareEl, { duration: 1, scale: 2, fill: "yellow", yoyo: true });
    }
    function genRand(min, max, decimalPlaces) {
        var rand = Math.random() * (max - min) + min;
        var power = Math.pow(10, decimalPlaces);
        return Math.floor(rand * power) / power;
    }
};

export const globalAnimationStyles = function () {
    gsap.utils.toArray(".reveal").forEach(function (elem) {
        hide(elem); // assure that the element is hidden when scrolled into view

        ScrollTrigger.create({
            trigger: elem,
            onEnter: function () {
                animateFrom(elem);
            },
            onEnterBack: function () {
                animateFrom(elem, -1);
            },
            onLeave: function () {
                hide(elem);
            }, // assure that the element is hidden when scrolled into view
        });
    });

    // console.log("Global");
};

export const animateFrom = function (elem, direction) {
    direction = direction || 1;
    var x = 0,
        y = direction * 100;
    if (elem.classList.contains("reveal--left")) {
        x = -100;
        y = 0;
    } else if (elem.classList.contains("reveal--right")) {
        x = 100;
        y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
        elem,
        { x: x, y: y, autoAlpha: 0 },
        {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto",
        }
    );
};

export const hide = function (elem) {
    // console.log(elem);
    gsap.set(elem, { autoAlpha: 0 });
};
